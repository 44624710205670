
import { createApp, defineAsyncComponent } from 'vue'

// Plugins
import Quasar from 'quasar/src/vue-plugin.js';import Meta from 'quasar/src/plugins/meta/Meta.js';import LoadingBar from 'quasar/src/plugins/loading-bar/LoadingBar.js';import Cookies from 'quasar/src/plugins/cookies/Cookies.js';
import _ from 'lodash'
import moment from 'moment-timezone'
import FormBuilder from './../../packages/form-builder'
import { getToken } from './helpers'
import WebSocket from './plugins/websocket'
import { centrifuge } from 'centrifuge'
import SocketHelper from './plugins/socket'
import { channelAdapters, channels } from './socket-helpers/socket-helpers'
import services from './services/services'
import allUtils from './utils'
import Portal from '../../packages/portal'
import { RouterUtils } from './utils/route-utils'
import { rawRoutes } from './config/PageRoutes'
import { rawSimpleRoutes } from './config/SimplePageRoutes'

import qs from 'qs'
import JsonViewer from 'vue-json-viewer'

import './chat'
import './style'

// Vuex
import { store } from './vueX/store'

// Router
import { router } from './router'

// Component
import App from './App.vue'

// Translation
import i18n from './../../i18n'

export function bootstrap (root, eventBus, options) {
  const app = createApp(App)
    
  app.config.globalProperties._ = _
  app.config.globalProperties.$eventBus = eventBus
  app.config.globalProperties.$appOptions = options.appOptions
  app.config.globalProperties.$defaultAppOptions = options.defaultAppOptions
  app.config.globalProperties.$entities = options.entities
  app.config.globalProperties.$instructions = options.instructions
  app.config.globalProperties.$permissions = options.permissions
  app.config.globalProperties.$moment = moment.tz.setDefault(options.appOptions.formats.defaultTimezone)
  app.config.globalProperties._ = _
  app.config.globalProperties.$service = services

  
  const permissions = Object.keys(options.permissions)

  let rawRoutesImport = rawRoutes;
  if (window.appOptions?.versionType == 'simple') {
    rawRoutesImport = rawSimpleRoutes;
  }

  const routerUtil = new RouterUtils(rawRoutesImport, permissions, services.permission)

  app.config.globalProperties.$utils = {
    ...allUtils,
    router: routerUtil
  }

  app.use(i18n)
  app.use(router)
  app.use(store)
  app.use(Quasar, {
    config: {
      animations: 'all'
    },
    plugins: {
      Meta,
      LoadingBar,
      Cookies
    }
  })


  app.use(FormBuilder, { permissions: routerUtil.allPermissions })
  app.use(Portal)
  app.use(WebSocket, {
    service: 'wss://centrifugo.orderadmin.eu/connection/websocket',
    options: () => {
      return {
        debug: true,
        name: 'app',
        getToken: getToken,
      }
    }
  })

  app.use(SocketHelper, {
    socket: centrifuge,
    channels,
    store,
    adapters: channelAdapters
  })

  app.component('object-code', defineAsyncComponent(() => import('./components/history-collapse/ObjectCode.vue')))
  app.component('array-code', defineAsyncComponent(() => import('./components/history-collapse/ArrayCode.vue')))
  app.component('sticky-bottom-header', defineAsyncComponent(() => import('./components/modals/StickyBottomHeader.vue')))
  app.component('role-row', defineAsyncComponent(() => import('./components/roles/RoleRow.vue')))
  app.component('role-card', defineAsyncComponent(() => import('./components/roles/RoleCard.vue')))
  app.component('q-legend', defineAsyncComponent(() => import('./components/global/QLegend.vue')))
  app.component('q-token', defineAsyncComponent(() => import('./components/global/QToken.vue')))
  app.use(JsonViewer)

  app.config.globalProperties.$vibrate = () => {
    if (navigator) {
      navigator.vibrate(100)
    }
  }

  store.commit('setCurrentUser', options.user)

  if (options.route) {
    router.push(options.route)
  }

  window.analytics.identify(options.user.id, {
    id: options.user.id,
    username: options.user.username,
    email: options.user.email,
    roles: options.user.roles
  })

  router.beforeEach((to, from, next) => {
    const path = routerUtil.transformPath(to)
    const route = routerUtil.getRouteByPath(path)

    // Submit route to analitics
    window.analytics.page(path)

    if (route) {
      if (route.entityClass) {
        const objects = Array.isArray(route.entityClass)
          ? route.entityClass
          : [route.entityClass]

        if (objects.length > 0) {
          routerUtil.getPermissions(objects)
            .then(permissions => {
              app.config.globalProperties.$formBuilder.setPermissions(permissions)
            })
        }
      }
      // if (route.entityClass) {
      //   if (Array.isArray(route.entityClass)) {
      //     if (route.entityClass.find(x => x && !permissions.includes(x))) {
      //       next('/unauthorized')
      //       return
      //     }
      //   } else if (!permissions.includes(route.entityClass)) {
      //     next('/unauthorized')
      //     return
      //   }
      // }

      if (route.options && route.options.query && Object.keys(to.query).filter(x => x !== 'page').length <= 0) {
        let query = qs.stringify(route.options.query)

        if (!query.startsWith('?')) {
          query = `?${query}`
        }

        next(`${to.fullPath}${query}`)
        return
      }
    }

    next()
  })

  return app
}
